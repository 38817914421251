'use strict';

(function () {
	let Locr = {
		createNS: function (namespace) {
			let nsparts = namespace.split('.');
			let parent = this;
	
			if (nsparts[0] === 'Locr') {
				nsparts = nsparts.slice(1);
			}
	
			for (let i = 0; i < nsparts.length; i++) {
				let partname = nsparts[i];
				if (typeof parent[partname] === 'undefined') {
					parent[partname] = {};
				}
				parent = parent[partname];
			}
	
			return parent;
		}
	};

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		let requires = ['color', 'convert', 'length', 'geo', 'math'];
		for (let requireItem of requires) {
			let required = require('./src/' + requireItem);
			for (let item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();
'use strict';

(function () {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		let requires = ['error', 'math'];
		for (let requireItem of requires) {
			let required = require('./' + requireItem);
			for (let item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}
	
	const decartaColors = {
		BK: "#000000",
		WH: "#FFFFFF",
		GR: "#808080",
		SI: "#C0C0C0",
		MR: "#800000",
		RD: "#FF0000",
		GN: "#008000",
		LI: "#00FF00",
		OL: "#808000",
		YE: "#FFFF00",
		NA: "#000080",
		BL: "#0000FF",
		PU: "#800080",
		FU: "#FF00FF",
		TE: "#008080",
		AQ: "#00FFFF"
	};

	const knownColors = {
		aliceblue: "#F0F8FF",
		antiqueqhite: "#FAEBD7",
		aqua: "#00FFFF",
		aquamarine: "#7FFFD4",
		azure: "#F0FFFF",
		beige: "#F5F5DC",
		bisque: "#FFE4C4",
		black: "#000000",
		blanchedalmond: "#FFEBCD",
		blue: "#0000FF",
		blueviolet: "#8A2BE2",
		brown: "#A52A2A",
		burlywood: "#DEB887",
		cadetblue: "#5F9EA0",
		chartreuse: "#7FFF00",
		chocolate: "#D2691E",
		coral: "#FF7F50",
		cornflowerblue: "#6495ED",
		cornsilk: "#FFF8DC",
		crimson: "#DC143C",
		cyan: "#00FFFF",
		darkblue: "#00008B",
		darkcyan: "#008B8B",
		darkgoldenrod: "#B8860B",
		darkgray: "#A9A9A9",
		darkgrey: "#A9A9A9",
		darkgreen: "#006400",
		darkkhaki: "#BDB76B",
		darkmagenta: "#8B008B",
		darkolivegreen: "#556B2F",
		darkorange: "#FF8C00",
		darkorchid: "#9932CC",
		darkred: "#8B0000",
		darksalmon: "#E9967A",
		darkseagreen: "#8FBC8B",
		darkslateblue: "#483D8B",
		darkslategray: "#2F4F4F",
		darkslategrey: "#2F4F4F",
		darkturquoise: "#00CED1",
		darkviolet: "#9400D3",
		deeppink: "#FF1493",
		deepskyblue: "#00BFFF",
		dimgray: "#696969",
		dimgrey: "#696969",
		dodgerblue: "#1E90FF",
		firebrick: "#B22222",
		floralwhite: "#FFFAF0",
		forestgreen: "#228B22",
		fuchsia: "#FF00FF",
		gainsboro: "#DCDCDC",
		ghostwhite: "#F8F8FF",
		gold: "#FFD700",
		goldenrod: "#DAA520",
		gray: "#808080",
		grey: "#808080",
		green: "#008000",
		greenyellow: "#ADFF2F",
		honeydew: "#F0FFF0",
		hotpink: "#FF69B4",
		indianred: "#CD5C5C",
		indigo: "#4B0082",
		ivory: "#FFFFF0",
		khaki: "#F0E68C",
		lavender: "#E6E6FA",
		lavenderblush: "#FFF0F5",
		lawngreen: "#7CFC00",
		lemonchiffon: "#FFFACD",
		lightblue: "#ADD8E6",
		lightcoral: "#F08080",
		lightcyan: "#E0FFFF",
		lightgoldenrodyellow: "#FAFAD2",
		lightgray: "#D3D3D3",
		lightgrey: "#D3D3D3",
		lightgreen: "#90EE90",
		lightpink: "#FFB6C1",
		lightsalmon: "#FFA07A",
		lightseagreen: "#20B2AA",
		lightskyblue: "#87CEFA",
		lightslategray: "#778899",
		lightslategrey: "#778899",
		lightsteelblue: "#B0C4DE",
		lightyellow: "#FFFFE0",
		lime: "#00FF00",
		limegreen: "#32CD32",
		linen: "#FAF0E6",
		locr: "#FF7300",
		magenta: "#FF00FF",
		maroon: "#800000",
		mediumaquamarine: "#66CDAA",
		mediumblue: "#0000CD",
		mediumorchid: "#BA55D3",
		mediumpurple: "#9370DB",
		mediumseagreen: "#3CB371",
		mediumslateblue: "#7B68EE",
		mediumspringgreen: "#00FA9A",
		mediumturquoise: "#48D1CC",
		mediumvioletred: "#C71585",
		midnightblue: "#191970",
		mintcream: "#F5FFFA",
		mistyrose: "#FFE4E1",
		moccasin: "#FFE4B5",
		navajowhite: "#FFDEAD",
		navy: "#000080",
		oldlace: "#FDF5E6",
		olive: "#808000",
		olivedrab: "#6B8E23",
		orange: "#FFA500",
		orangered: "#FF4500",
		orchid: "#DA70D6",
		palegoldenrod: "#EEE8AA",
		palegreen: "#98FB98",
		paleturquoise: "#AFEEEE",
		palevioletred: "#DB7093",
		papayawhip: "#FFEFD5",
		peachpuff: "#FFDAB9",
		peru: "#CD853F",
		pink: "#FFC0CB",
		plum: "#DDA0DD",
		powderblue: "#B0E0E6",
		purple: "#800080",
		red: "#FF0000",
		rosybrown: "#BC8F8F",
		royalblue: "#4169E1",
		saddlebrown: "#8B4513",
		salmon: "#FA8072",
		sandybrown: "#F4A460",
		seagreen: "#2E8B57",
		seashell: "#FFF5EE",
		sienna: "#A0522D",
		silver: "#C0C0C0",
		skyblue: "#87CEEB",
		slateblue: "#6A5ACD",
		slategray: "#708090",
		slategrey: "#708090",
		snow: "#FFFAFA",
		springgreen: "#00FF7F",
		steelblue: "#4682B4",
		tan: "#D2B48C",
		teal: "#008080",
		thistle: "#D8BFD8",
		tomato: "#FF6347",
		transparent: "#FFFFFFFF",
		turquoise: "#40E0D0",
		violet: "#EE82EE",
		wheat: "#F5DEB3",
		white: "#FFFFFF",
		whitesmoke: "#F5F5F5",
		yellow: "#FFFF00",
		yellowgreen: "#9ACD32"
	};

	Locr.Color = class Color {
		constructor(param1, param2, param3, param4) {
			this._red = 0;
			this._green = 0;
			this._blue = 0;
			this._alpha = 255;

			let param1Type = typeof param1;
			switch (param1Type) {
				case 'string':
					this.Parse(param1);
					break;

				case 'number':
					let param2Type = typeof param2;
					let param3Type = typeof param3;
					let param4Type = typeof param4;
					if (param2Type === 'number' && param3Type === 'number') {
						this.Red = param1;
						this.Green = param2;
						this.Blue = param3;
						if (param4Type === 'number') {
							this.Alpha = param4;
						}
					}
					break;
				
				case 'object':
					if (param1 instanceof Array) {
						if (!this.ParseJsonArray(JSON.stringify(param1))) {
							throw new Locr.ArgumentError(Locr.Error.INVALID_TYPE, 'color', 1, 'The array-value of the parameter "color" for new Locr.Color(color) is invalid.');
						}
						return;
					}
					throw new Locr.ArgumentError(Locr.Error.INVALID_TYPE, 'color', 1, 'The type of the parameter "color" for new Locr.Color(color) must of type "string", "number" or "array". Type "' + param1Type + '" was given.');
				
				case 'undefined': // ignore
					break;
				
				default:
					throw new Locr.ArgumentError(Locr.Error.INVALID_TYPE, 'color', 1, 'The type of the parameter "color" for new Locr.Color(color) must of type "string", "number" or "array". Type "' + param1Type + '" was given.');
			}
		}

		get Red() {
			return this._red;
		}

		set Red(value) {
			if (typeof value !== 'number') {
				throw new Locr.ArgumentError(Locr.Error.INVALID_TYPE, 'value', 1, 'The value for Color.Red(value) must of type "number".');
			}

			if (value < 0 || value > 255) {
				throw new Locr.ArgumentError(Locr.Error.OUT_OF_RANGE, 'value', 1, 'The argument for the value Color.Red (' + value + ') is out of range. It must be between 0 and 255.');
			}

			this._red = value;
		}

		get Green() {
			return this._green;
		}

		set Green(value) {
			if (typeof value !== 'number') {
				throw new Locr.ArgumentError(Locr.Error.INVALID_TYPE, 'value', 1, 'The value for Color.Green(value) must of type "number".');
			}

			if (value < 0 || value > 255) {
				throw new Locr.ArgumentError(Locr.Error.OUT_OF_RANGE, 'value', 1, 'The argument for the value Color.Green (' + value + ') is out of range. It must be between 0 and 255.');
			}

			this._green = value;
		}

		get Blue() {
			return this._blue;
		}

		set Blue(value) {
			if (typeof value !== 'number') {
				throw new Locr.ArgumentError(Locr.Error.INVALID_TYPE, 'value', 1, 'The value for Color.Blue(value) must of type "number".');
			}

			if (value < 0 || value > 255) {
				throw new Locr.ArgumentError(Locr.Error.OUT_OF_RANGE, 'value', 1, 'The argument for the value Color.Blue (' + value + ') is out of range. It must be between 0 and 255.');
			}

			this._blue = value;
		}

		get Alpha() {
			return this._alpha;
		}

		set Alpha(value) {
			if (typeof value !== 'number') {
				throw new Locr.ArgumentError(Locr.Error.INVALID_TYPE, 'value', 1, 'The value for Color.Alpha(value) must of type "number".');
			}

			if (value < 0 || value > 255) {
				throw new Locr.ArgumentError(Locr.Error.OUT_OF_RANGE, 'value', 1, 'The argument for the value Color.Alpha (' + value + ') is out of range. It must be between 0 and 255.');
			}

			this._alpha = value;
		}

		Reset() {
			this._red = 0;
			this._green = 0;
			this._blue = 0;
			this._alpha = 255;
		}

		Colorize(color) {
			if (!(color instanceof Color)) {
				color = new Color(color);
			}

			return new Color(Math.round(this.Red * color.Red / 255.0), Math.round(this.Green * color.Green / 255.0), Math.round(this.Blue * color.Blue / 255.0), Math.round(this.Alpha * color.Alpha / 255.0));
		}

		Grayscale() {
			let grayValue = Math.floor(this._red * 0.299 + this._green * 0.587 + this._blue * 0.114);
			return new Color(grayValue, grayValue, grayValue);
		}

		Invert() {
			return new Color(255 - this.Red, 255 - this.Green, 255 - this.Blue, this.Alpha);
		}

		InvertLuminescence() {
			let hsl = this.GetHSLValue();
			let newColor = new Color();
			newColor.SetHSLValue({
				h: hsl.h,
				s: hsl.s,
				l: 1.0 - hsl.l
			});
			return newColor;
		}

		GetHSLValue() {
			let hsl = {
				h: 0,
				s: 0,
				l: 0
			};

			let min = 1.0;
			let max = 0.0;

			let red = this._red / 255.0;
			let green = this._green / 255.0;
			let blue = this._blue / 255.0;

			if (red < min) {
				min = red;
			}
			if (green < min) {
				min = green;
			}
			if (blue < min) {
				min = blue;
			}
			if (red > max) {
				max = red;
			}
			if (green > max) {
				max = green;
			}
			if (blue > max) {
				max = blue;
			}

			hsl.l = (max + min) / 2.0;

			if (max == min) {
				hsl.s = 0.0;
			} else {
				hsl.s = (hsl.l < 0.5) ? (max - min) / (max + min) : (max - min) / (2.0 - max - min);
			}

			if (red == max) {
				hsl.h = (green - blue) / (max - min);
			} else if (green == max) {
				hsl.h = 2.0 + (blue - red) / (max - min);
			} else if (blue == max) {
				hsl.h = 4.0 + (red - green) / (max - min);
			}

			hsl.h*= 60.0;
			if (hsl.h < 0) {
				hsl.h+= 360.0;
			}

			return hsl;
		}

		SetHSLValue(hsl) {
			if (hsl.h < 0.0 || hsl.h > 360.0) {
				throw "The argument for h[hue] (" + hsl.h + ") is out of range. It must be between 0.0 and 360.0.";
			}
			if (hsl.s < 0.0 || hsl.s > 1.0) {
				throw "The argument for s[saturation] (" + hsl.s + ") is out of range. It must be between 0.0 and 1.0.";
			}
			if (hsl.l < 0.0 || hsl.l > 1.0) {
				throw "The argument for l[lightness] (" + hsl.l + ") is out of range. It must be between 0.0 and 1.0.";
			}

			this.Reset();

			if (hsl.s == 0) {
				this.Red = parseInt(Math.round(hsl.l * 255.0));
				this.Green = parseInt(Math.round(hsl.l * 255.0));
				this.Blue = parseInt(Math.round(hsl.l * 255.0));
			} else {
				let t3 = [0, 0, 0];
				let c = [0, 0, 0];
				let t2 = (hsl.l < 0.5) ? hsl.l * (1.0 + hsl.s) : hsl.l + hsl.s - hsl.l * hsl.s;
				let t1 = 2.0 * hsl.l - t2;
				hsl.h/= 360.0;
				t3[0] = hsl.h + 1.0 / 3.0;
				t3[1] = hsl.h;
				t3[2] = hsl.h - 1.0 / 3.0;
				for(let i = 0; i < 3; i++) {
					if (t3[i] < 0.0) {
						t3[i]+= 1.0;
					}
					if (t3[i] > 1.0) {
						t3[i]-= 1.0;
					}
					if (6.0 * t3[i] < 1.0) {
						c[i] = t1 + (t2 - t1) * 6.0 * t3[i];
					} else if (2.0 * t3[i] < 1.0) {
						c[i] = t2;
					} else if (3.0 * t3[i] < 2.0) {
						c[i] = t1 + (t2 - t1) * ((2.0 / 3.0) - t3[i]) * 6.0;
					} else {
						c[i] = t1;
					}
				}

				this.Red = parseInt(Math.round(c[0] * 255.0));
				this.Green = parseInt(Math.round(c[1] * 255.0));
				this.Blue = parseInt(Math.round(c[2] * 255.0));
			}
		}

		Parse(color) {
			if (typeof color !== 'string') {
				throw new Locr.ArgumentError(Locr.Error.INVALID_TYPE, 'color', 1, 'The value for Color.Parse(color) must of type "string".');
			}

			this.Reset();

			if (this.ParseDecarta(color.toUpperCase())) {
				return;
			}
			if (this.ParseKnown(color.toLowerCase())) {
				return;
			}
			if (this.ParseHex(color)) {
				return;
			}
			if (this.ParseRgb(color)) {
				return;
			}
			if (this.ParseRgba(color)) {
				return;
			}
			if (this.ParseCmyk(color)) {
				return;
			}
			if (this.ParseJsonArray(color)) {
				return;
			}

			throw new Locr.ArgumentError(Locr.Error.INVALID_VALUE, 'color', 1, 'The given string "' + color + '" could not been parsed to a color.');
		}

		ParseDecarta(color) {
			if (typeof decartaColors[color] !== 'undefined') {
				return this.ParseHex(decartaColors[color]);
			}

			return false;
		}

		ParseKnown(color) {
			if (typeof knownColors[color] !== 'undefined') {
				return this.ParseHex(knownColors[color]);
			}

			return false;
		}

		ParseHex(color) {
			let hexColorRegex = /^\s*#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})?\s*$/;
			let hexColorMatch = color.match(hexColorRegex);
			if (!hexColorMatch) {
				return false;
			}

			this.Red = parseInt(hexColorMatch[1], 16);
			this.Green = parseInt(hexColorMatch[2], 16);
			this.Blue = parseInt(hexColorMatch[3], 16);
			if (hexColorMatch[4]) {
				this.Alpha = parseInt(hexColorMatch[4], 16);
			}

			return true;
		}

		ParseRgb(color) {
			let rgbColorRegex = /^\s*rgb\s*\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*\)\s*$/;
			let rgbColorMatch = color.match(rgbColorRegex);
			if (!rgbColorMatch) {
				return false;
			}

			this.Red = parseInt(rgbColorMatch[1]);
			this.Green = parseInt(rgbColorMatch[2]);
			this.Blue = parseInt(rgbColorMatch[3]);

			return true;
		}

		ParseRgba(color) {
			let rgbaColorRegex = /^\s*rgba\s*\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]+(\.[0-9]+)?)\s*\)\s*$/;
			let rgbaColorMatch = color.match(rgbaColorRegex);
			if (!rgbaColorMatch) {
				return false;
			}

			this.Red = parseInt(rgbaColorMatch[1]);
			this.Green = parseInt(rgbaColorMatch[2]);
			this.Blue = parseInt(rgbaColorMatch[3]);
			if (rgbaColorMatch[4].indexOf('.') !== -1) {
				this.Alpha = Math.round(parseFloat(rgbaColorMatch[4]) * 255);
			} else {
				this.Alpha = parseInt(rgbaColorMatch[4]);
			}

			return true;
		}

		ParseCmyk(color) {
			let cmykColorRegex = /^\s*cmyk\s*\(\s*([0-9]+(\.[0-9]+)?)\s*%?\s*,\s*([0-9]+(\.[0-9]+)?)\s*%?\s*,\s*([0-9]+(\.[0-9]+)?)\s*%?\s*,\s*([0-9]+(\.[0-9]+)?)\s*%?\s*\)\s*$/;
			let cmykColorMatch = color.match(cmykColorRegex);
			if (!cmykColorMatch) {
				return false;
			}

			let cyan = parseFloat(cmykColorMatch[1]);
			let magenta = parseFloat(cmykColorMatch[3]);
			let yellow = parseFloat(cmykColorMatch[5]);
			let black = parseFloat(cmykColorMatch[7]);

			this.Red = Math.round(255 * (1 - cyan / 100) * (1 - black / 100));
			this.Green = Math.round(255 * (1 - magenta / 100) * (1 - black / 100));
			this.Blue = Math.round(255 * (1 - yellow / 100) * (1 - black / 100));

			return true;
		}

		ParseJsonArray(color) {
			try {
				let json = JSON.parse(color);
				if (!(json instanceof Array)) {
					return false;
				}
				if (json.length < 3 || json.length > 4) {
					return false;
				}

				this.Red = json[0];
				this.Green = json[1];
				this.Blue = json[2];
				if (json.length === 4) {
					this.Alpha = json[3];
				}

				return true;
			} catch (exc) {
				return false;
			}
		}

		ToHEXString() {
			let padding = 2;
			let redString = this._red.toString(16);
			let greenString = this._green.toString(16);
			let blueString = this._blue.toString(16);
			while (redString.length < padding) {
				redString = '0' + redString;
			}
			while (greenString.length < padding) {
				greenString = '0' + greenString;
			}
			while (blueString.length < padding) {
				blueString = '0' + blueString;
			}

			let hexString = '#' + redString + greenString + blueString;
			if (this._alpha < 255) {
				let alphaString = this._alpha.toString(16);
				while (alphaString.length < padding) {
					alphaString = '0' + alphaString;
				}
				hexString += alphaString;
			}
			return hexString.toUpperCase();
		}

		ToRGBString() {
			let rgbString = 'rgb(' + this.Red + ', ' + this.Green + ', ' + this.Blue + ')';
			return rgbString;
		}

		ToRGBAString() {
			let rgbaString = 'rgba(' + this.Red + ', ' + this.Green + ', ' + this.Blue + ', ' + Locr.Math.Round(this.Alpha / 255.0, 2) + ')';
			return rgbaString;
		}

		ToCMYKString() {
			let r = this.Red / 255;
			let g = this.Green / 255;
			let b = this.Blue / 255;
			let black = 1 - Math.max(r, g, b);
			
			let cyan = Math.round((1 - r - black) / (1 - black) * 100);
			let magenta = Math.round((1 - g - black) / (1 - black) * 100);
			let yellow = Math.round((1 - b - black) / (1 - black) * 100);
			
			let cmykString = 'cmyk(' + cyan + '%, ' + magenta + '%, ' + yellow + '%, ' + black + '%)';
			return cmykString;
		}
	}

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function () {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Object.defineProperty(Locr, 'PRINT_DPI', {
		value: 300,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr, 'DISPLAY_DPI', {
		value: 96,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr, 'CENTIMER_PER_INCH', {
		value: 2.54,
		writable: false,
		enumerable: true,
		configurable: false
	});

	Locr.Convert = class Convert {
		/**
		 * @param {number} value
		 * @param {number} dpi [optional]
		 */
		static CentimeterToPixel(value, dpi) {
			dpi = (dpi) ? dpi : Locr.DISPLAY_DPI;
			return Math.round(value * dpi / Locr.CENTIMER_PER_INCH);
		}

		/**
		 * @param {number} value
		 * @param {number} dpi [optional]
		 */
		static CmToPx(value, dpi) {
			return this.CentimeterToPixel(value, dpi);
		}

		/**
		 * @param {number} value 
		 * @param {number} dpi [optional]
		 */
		static PixelToCentimeter(value, dpi) {
			dpi = (dpi) ? dpi : Locr.DISPLAY_DPI;
			return value / dpi * Locr.CENTIMER_PER_INCH;
		}

		/**
		 * @param {number} value 
		 * @param {number} dpi [optional]
		 */
		static PxToCm(value, dpi) {
			return this.PixelToCentimeter(value, dpi);
		}
	}

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function () {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		let requires = [];
		for (let requireItem of requires) {
			let required = require('./' + requireItem);
			for (let item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.Error = class LocrError extends Error {
		constructor(code = Locr.Error.UNKNOWN, ...params) {
			super(...params);

			if (Error.captureStackTrace) {
				Error.captureStackTrace(this, LocrError);
			}

			this.code = code;
		}
	}

	Locr.ArgumentError = class ArgumentError extends Error {
		constructor(code = Locr.Error.UNKNOWN, name = '', position = 1, ...params) {
			super(...params);

			if (Error.captureStackTrace) {
				Error.captureStackTrace(this, ArgumentError);
			}

			this.code = code;
			this.name = name;
			this.position = position;
		}
	}

	Object.defineProperty(Locr.Error, 'UNKNOWN', {
		value: 1,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Error, 'INVALID_ARGUMENT', {
		value: 2,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Error, 'INVALID_TYPE', {
		value: 3,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Error, 'INVALID_VALUE', {
		value: 4,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Error, 'OUT_OF_RANGE', {
		value: 5,
		writable: false,
		enumerable: true,
		configurable: false
	});

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function () {
	let Locr = {};
	
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		let requires = ['../locr'];
		for (let requireItem of requires) {
			let required = require('./' + requireItem);
			for (let item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.createNS('Locr.Geo');

	Object.defineProperty(Locr.Geo, 'EQUATORIAL_RADIUS', {
		value: 6378137,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Geo, 'METER_PER_DEGREE_LATITUDE', {
		value: 1852.2,
		writable: false,
		enumerable: true,
		configurable: false
	});

	Object.defineProperty(Locr.Geo, 'COORDINATE_FORMAT_DECIMAL', {
		value: 'D.XXYYZZ',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Geo, 'COORDINATE_FORMAT_SIGNED_DEGREES_MINUTES_SECONDS', {
		value: 'signed_DMS',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Geo, 'COORDINATE_FORMAT_DEGREES_MINUTES_SECONDS_DIRECTION', {
		value: 'DMS_direction',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Geo, 'COORDINATE_FORMAT_SIGNED_DEGREES_MINUTES_SECONDS_DECIMAL', {
		value: 'signed_DMS.S',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Geo, 'COORDINATE_FORMAT_DEGREES_MINUTES_SECONDS_DECIMAL_DIRECTION', {
		value: 'DMS.S_direction',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Geo, 'COORDINATE_FORMAT_SIGNED_DEGREES_MINUTES_DECIMAL', {
		value: 'signed_DM.M',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Geo, 'COORDINATE_FORMAT_DEGREES_MINUTES_DECIMAL_DIRECTION', {
		value: 'DM.M_direction',
		writable: false,
		enumerable: true,
		configurable: false
	});

	Locr.Geo.CalculateNormalizedBoundings = function (boundings, rectangle) {
		let newBoundings = {
			LatitudeMin: boundings.LatitudeMin,
			LatitudeMax: boundings.LatitudeMax,
			LongitudeMin: boundings.LongitudeMin,
			LongitudeMax: boundings.LongitudeMax
		};
		let ldDegreesWidth = newBoundings.LongitudeMax - newBoundings.LongitudeMin;

		let ldNormalizedWidth = ldDegreesWidth / 360.0;
		let ldNormalizedHeight = Locr.Geo.Latitude2Normalized(newBoundings.LatitudeMin) - Locr.Geo.Latitude2Normalized(newBoundings.LatitudeMax);
		let ldNormalizedRatio = (ldNormalizedHeight != 0) ? ldNormalizedWidth / ldNormalizedHeight : 1;
		let ldMapRatio = rectangle.WidthPx / rectangle.HeightPx;

		if (ldNormalizedRatio == 0) {
			ldNormalizedRatio = 1;
		}
		if (ldMapRatio >= ldNormalizedRatio) {
			newBoundings.LongitudeMin-= (ldDegreesWidth * ldMapRatio / ldNormalizedRatio - ldDegreesWidth) / 2;
			newBoundings.LongitudeMax+= (ldDegreesWidth * ldMapRatio / ldNormalizedRatio - ldDegreesWidth) / 2;
		} else {
			let ldNormalizedMin = Locr.Geo.Latitude2Normalized(newBoundings.LatitudeMin);
			let ldNormalizedMax = Locr.Geo.Latitude2Normalized(newBoundings.LatitudeMax);

			ldNormalizedMin+= (ldNormalizedWidth / ldMapRatio - ldNormalizedHeight) / 2;
			ldNormalizedMax-= (ldNormalizedWidth / ldMapRatio - ldNormalizedHeight) / 2;

			newBoundings.LatitudeMin = Locr.Geo.Normalized2Latitude(ldNormalizedMin);
			newBoundings.LatitudeMax = Locr.Geo.Normalized2Latitude(ldNormalizedMax);
		}

		if ((newBoundings.LongitudeMax - newBoundings.LongitudeMin) != 0) {
			newBoundings.LatitudeMin = Locr.Geo.Normalized2Latitude(Locr.Geo.Latitude2Normalized(newBoundings.LatitudeMin));
			newBoundings.LatitudeMax = Locr.Geo.Normalized2Latitude(Locr.Geo.Latitude2Normalized(newBoundings.LatitudeMax));
			newBoundings.LongitudeMin = Locr.Geo.Normalized2Longitude(Locr.Geo.Longitude2Normalized(newBoundings.LongitudeMin));
			newBoundings.LongitudeMax = Locr.Geo.Normalized2Longitude(Locr.Geo.Longitude2Normalized(newBoundings.LongitudeMax));
		}

		return new Locr.Geo.Boundings(newBoundings.LatitudeMin, newBoundings.LatitudeMax, newBoundings.LongitudeMin, newBoundings.LongitudeMax);
	}

	/**
	 * @param {Locr.Geo.Position} position
	 * @param {Locr.Geo.Boundings} boundings
	 * @param {Locr.Rectangle} rectangle
	 * @param {object]} opts { round: false }
	 * @returns {object} { x: <number>, y: <number> }
	 */
	Locr.Geo.CalculateXYByPosition = function (position, boundings, rectangle, opts) {
		if (boundings.LatitudeMax === boundings.LatitudeMin) {
			return {
				x: 0,
				y: 0
			};
		}

		let normalizedBoundings = Locr.Geo.CalculateNormalizedBoundings(boundings, rectangle);
		let totalPixels = rectangle.WidthPx * 360 / (normalizedBoundings.LongitudeMax - normalizedBoundings.LongitudeMin);
		let xLongMin = Locr.Geo.Longitude2Normalized(normalizedBoundings.LongitudeMin) * totalPixels;
		let yLatMax = Locr.Geo.Latitude2Normalized(normalizedBoundings.LatitudeMax) * totalPixels;
		let xLong = Locr.Geo.Longitude2Normalized(position.Longitude) * totalPixels;
		let yLat = Locr.Geo.Latitude2Normalized(position.Latitude) * totalPixels;

		let xy = {
			x: xLong - xLongMin,
			y: yLat - yLatMax
		};
		if (opts && opts.round) {
			xy.x = Math.round(xy.x);
			xy.y = Math.round(xy.y);
		}

		return xy;
	}

	/**
	 * @param {object} xy { x: <number>, y: <number> }
	 * @param {Locr.Geo.Boundings} boundings
	 * @param {Locr.Rectangle} rectangle
	 * @returns {Locr.Geo.Position}
	 */
	Locr.Geo.CalculatePositionByXY = function (xy, boundings, rectangle) {
		if (boundings.LongitudeMax === boundings.LongitudeMin) {
			return new Locr.Geo.Position(boundings.LatitudeMax, boundings.LongitudeMin);
		}

		let normalizedBoundings = Locr.Geo.CalculateNormalizedBoundings(boundings, rectangle);
		let totalPixels = rectangle.WidthPx * 360 / (normalizedBoundings.LongitudeMax - normalizedBoundings.LongitudeMin);
		let xTotal = Locr.Geo.Longitude2Normalized(normalizedBoundings.LongitudeMin) * totalPixels + xy.x;
		let yTotal = Locr.Geo.Latitude2Normalized(normalizedBoundings.LatitudeMax) * totalPixels + xy.y;
		let longitude = Locr.Geo.Normalized2Longitude(xTotal / totalPixels);
		let latitude = Locr.Geo.Normalized2Latitude(yTotal / totalPixels);

		return new Locr.Geo.Position(latitude, longitude);
	}

	Locr.Geo.Latitude2Normalized = function (latitude) {
		return Math.log(Math.tan((90.0 - (latitude)) * Math.PI / 360.0)) / (2.0 * Math.PI) + 0.5;
	}

	Locr.Geo.Longitude2Normalized = function(longitude) {
		return (longitude + 180.0) / 360.0;
	}

	Locr.Geo.Normalized2Latitude = function (y) {
		return 90.0 - Math.atan(Math.exp((y - 0.5) * 2.0 * Math.PI)) * 360.0 / Math.PI;
	}

	Locr.Geo.Normalized2Longitude = function(x) {
		return x * 360 - 180;
	}

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
		
		let requires = ['rectangle', 'geo/boundings', 'geo/g_polyline', 'geo/position'];
		for (let requireItem of requires) {
			let required = require('./' + requireItem);
			for (let item in required) {
				Locr[item] = required[item];
			}
		}
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function () {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		let requires = ['math'];
		for (let requireItem of requires) {
			let required = require('./' + requireItem);
			for (let item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	const CONVERT_FACTORS = {
		FROM: {
			'mm': {
				TO: {
					'mm': 1,
					'cm': 0.1,
					'in': 0.0393701,
					'dm': 0.01,
					'ft': 0.00328084,
					'm': 0.001,
					'km': 0.000001,
					'mi': 6.2137e-7
				}
			},
			'cm': {
				TO: {
					'mm': 10,
					'cm': 1,
					'in': 0.3937007874015748,
					'dm': 0.1,
					'ft': 0.0328084,
					'm': 0.01,
					'km': 0.00001,
					'mi': 6.2137e-6
				}
			},
			'in': {
				TO: {
					'mm': 25.4,
					'cm': 2.54,
					'in': 1,
					'dm': 0.254,
					'ft': 0.08333333333333333333,
					'm': 0.0254,
					'km': 2.54e-5,
					'mi': 1.5783e-5
				}
			},
			'dm': {
				TO: {
					'mm': 100,
					'cm': 10,
					'in': 3.93701,
					'dm': 1,
					'ft': 0.328084,
					'm': 0.1,
					'km': 0.0001,
					'mi': 6.213712121212e-5
				}
			},
			'm': {
				TO: {
					'mm': 1000,
					'cm': 100,
					'in': 39.3701,
					'dm': 10,
					'ft': 3.28084,
					'm': 1,
					'km': 0.001,
					'mi': 0.000621371
				}
			},
			'km': {
				TO: {
					'mm': 1000000,
					'cm': 100000,
					'in': 39370.1,
					'dm': 10000,
					'ft': 3280.84,
					'm': 1000,
					'km': 1,
					'mi': 0.621371
				}
			}
		}
	};

	Locr.Length = class Length {
		/**
		 * @param {string|number} length
		 * @param {string|number} unitOrDpi
		 * @param {number|string} dpi
		 */
		constructor(length, unitOrDpi, dpi) {
			this._value = 0.0;
			this._unit = 'm';
			this._dpi = Locr.Length.PIXEL_PER_INCH;

			unitOrDpi = unitOrDpi || '';

			if (typeof length !== 'undefined') {
				if (length instanceof Length) {
					this._value = length.Value;
					this._unit = length.Unit;
					this._dpi = length.DPI;
				} else {
					this.Parse(length, unitOrDpi, dpi);
				}
			}
		}

		/**
		 * @return {number}
		 */
		get DPI() {
			return this._dpi;
		}

		/**
		 * @param {number|string} dpi
		 */
		set DPI(dpi) {
			if (typeof dpi === 'string') {
				dpi = parseInt(dpi);
				if (isNaN(dpi)) {
					throw 'The string-value of dpi cannot been parsed as int.';
				}
			}
			if (typeof dpi !== 'number') {
				throw 'The type of dpi must be "number".';
			}
			if (dpi <= 0) {
				throw 'The value of dpi must be greater than 0.';
			}

			this._dpi = dpi;
		}

		/**
		 * @return {string}
		 */
		get Unit() {
			return this._unit;
		}

		/**
		 * @param {string} unit
		 */
		set Unit(unit) {
			this._unit = this.ParseUnit(unit);
		}

		/**
		 * @return {number}
		 */
		get Value() {
			return this._value;
		}

		/**
		 * @param {string|number} value
		 */
		set Value(value) {
			const valueType = typeof value;
			if (valueType === 'number') {
				this._value = value;
				return;
			} else if (valueType === 'string') {
				let parsedValue = parseFloat(value);
				if (!isNaN(parsedValue)) {
					this._value = parsedValue;
					return;
				}
			}

			this.Parse(value);
		}

		/**
		 * @param {string|number} length
		 * @param {string} unitOrDpi
		 * @param {number|string} dpi
		 */
		Parse(length, unitOrDpi, dpi) {
			unitOrDpi = unitOrDpi || '';
			let unitOrDpiType = typeof unitOrDpi;
			let dpiType = typeof dpi;
			let parsedValue = length;
			let lengthUnit = null;

			if (dpiType === 'undefined') {
				if (unitOrDpiType === 'number') {
					dpi = unitOrDpi;
					unitOrDpi = '';
					unitOrDpiType = 'string';
				} else if (unitOrDpiType === 'string') {
					let unitOrDpiIntParsed = parseInt(unitOrDpi);
					if (!isNaN(unitOrDpiIntParsed)) {
						dpi = unitOrDpiIntParsed;
						unitOrDpi = '';
						unitOrDpiType = 'string';
					}
				}
			} else if (dpiType === 'string') {
				dpi = parseInt(dpi);
				if (isNaN(dpi)) {
					throw 'The string-value of dpi cannot been parsed as int.';
				}
			}
			if (unitOrDpiType === 'string' && unitOrDpi !== '') {
				lengthUnit = this.ParseUnit(unitOrDpi);
			}

			if (typeof length === 'string') {
				length = length.toLowerCase();

				let lengthMatch = length.match(/^\s*(-?[0-9]+(\.[0-9]+)?)\s*(.+)?\s*$/);
				if (!lengthMatch) {
					throw 'Could not parse length.';
				}

				parsedValue = parseFloat(lengthMatch[1]);

				if (typeof lengthMatch[3] === 'string' && lengthMatch[3] !== '' && typeof unitOrDpi !== 'undefined' && unitOrDpi !== '') {
					throw 'To provide an explicit unit and a unit in the value is ambiguous.';
				}

				if (typeof lengthMatch[3] !== 'undefined' && unitOrDpi === '') {
					lengthUnit = this.ParseUnit(lengthMatch[3]);
				}
			}

			if (!lengthUnit) {
				throw 'A length-unit is required for Locr.Length.';
			}

			this._value = parsedValue;
			this._unit = lengthUnit;
			if (typeof dpi === 'number') {
				this._dpi = dpi;
			}
		}

		/**
		 * @param {string} unit 
		 */
		ParseUnit(unit) {
			unit = unit.toLowerCase();

			if (unit === '') {
				return Locr.Length.METER;
			}

			let unitMatch = unit.match(/^\s*(miles?|mi|meters?|m|kilometers?|km|decimeters?|dm|centimeters?|cm|millimeters?|mm|feets?|ft|points?|pt|pixels?|px|inches|inch|in)\s*$/);
			if (!unitMatch) {
				throw 'Could not parse unit: ' + unit;
			}

			if (unitMatch[1] === "" || unitMatch[1] === "m" || unitMatch[1] === "meter" || unitMatch[1] === "meters") {
				return Locr.Length.METER;
			} else if (unitMatch[1] === "km" || unitMatch[1] === "kilometer" || unitMatch[1] === "kilometers") {
				return Locr.Length.KILOMETER;
			} else if (unitMatch[1] === "dm" || unitMatch[1] === "decimeter" || unitMatch[1] === "decimeters") {
				return Locr.Length.DECIMETER;
			} else if (unitMatch[1] === "cm" || unitMatch[1] === "centimeter" || unitMatch[1] === "centimeters") {
				return Locr.Length.CENTIMETER;
			} else if (unitMatch[1] === "mm" || unitMatch[1] === "millimeter" || unitMatch[1] === "millimeters") {
				return Locr.Length.MILLIMETER;
			} else if (unitMatch[1] === "ft" || unitMatch[1] === "feet" || unitMatch[1] === "feets") {
				return Locr.Length.FEET;
			} else if (unitMatch[1] === "mi" || unitMatch[1] === "mile" || unitMatch[1] === "miles") {
				return Locr.Length.MILE;
			} else if (unitMatch[1] === "in" || unitMatch[1] === "inch" || unitMatch[1] === "inches") {
				return Locr.Length.INCH;
			} else if (unitMatch[1] === "pt" || unitMatch[1] === "point" || unitMatch[1] === "points") {
				return Locr.Length.POINT;
			} else if (unitMatch[1] === "px" || unitMatch[1] === "pixel" || unitMatch[1] === "pixels") {
				return Locr.Length.PIXEL;
			}

			throw 'Could not parse unit: ' + unit;
		}

		Add(length, unit) {
			let addValue = new Length(length, unit);
			return new Length(this._value + addValue.To(this._unit).Value, this._unit);
		}

		AddValue(value) {
			return new Length(this._value + value, this._unit);
		}

		Subtract(length, unit) {
			let subtractValue = new Length(length, unit);
			return new Length(this._value - subtractValue.To(this._unit).Value, this._unit);
		}

		SubtractValue(value) {
			return new Length(this._value - value, this._unit);
		}

		Multiply(length, unit) {
			let multiplyValue = new Length(length, unit);
			return new Length(this._value * multiplyValue.To(this._unit).Value, this._unit);
		}

		MultiplyBy(factor) {
			return new Length(this._value * factor, this._unit);
		}

		Divide(length, unit) {
			let divideValue = new Length(length, unit);
			return new Length(this._value / divideValue.To(this._unit).Value, this._unit);
		}

		DivideBy(factor) {
			return new Length(this._value / factor, this._unit);
		}

		/**
		 * @param {string} destinationUnit
		 * @return {Locr.Length}
		 */
		To(destinationUnit) {
			let currentValue = this._value;
			let currentUnit = this._unit;
			if (destinationUnit === currentUnit) {
				return new Locr.Length(currentValue, currentUnit);
			}

			if (currentUnit === Locr.Length.POINT) {
				currentValue /= Locr.Length.POINT_PER_INCH;
				currentUnit = Locr.Length.INCH;
			} else if (currentUnit === Locr.Length.PIXEL) {
				currentValue /= this._dpi;
				currentUnit = Locr.Length.INCH;
			}

			if (destinationUnit === Locr.Length.POINT || destinationUnit === Locr.Length.PIXEL) {
				if (currentUnit !== Locr.Length.INCH) {
					currentValue = new Locr.Length(currentValue, currentUnit).To(Locr.Length.INCH).Value;
					currentUnit = Locr.Length.INCH;
				}
				if (destinationUnit === Locr.Length.POINT) {
					return new Locr.Length(currentValue * Locr.Length.POINT_PER_INCH, Locr.Length.POINT);
				} else if (destinationUnit === Locr.Length.PIXEL) {
					return new Locr.Length(currentValue * this._dpi, Locr.Length.PIXEL);
				}
			}

			return new Locr.Length(currentValue * CONVERT_FACTORS.FROM[currentUnit].TO[destinationUnit], destinationUnit);
		}

		ToMM() {
			return this.To(Locr.Length.MILLIMETER);
		}

		ToMillimeter() {
			return this.To(Locr.Length.MILLIMETER);
		}

		ToCM() {
			return this.To(Locr.Length.CENTIMETER);
		}

		ToCentimeter() {
			return this.To(Locr.Length.CENTIMETER);
		}

		ToIN() {
			return this.To(Locr.Length.INCH);
		}

		ToInch() {
			return this.To(Locr.Length.INCH);
		}

		ToDM() {
			return this.To(Locr.Length.DECIMETER);
		}

		ToDecimeter() {
			return this.To(Locr.Length.DECIMETER);
		}

		ToFT() {
			return this.To(Locr.Length.FEET);
		}

		ToFeet() {
			return this.To(Locr.Length.FEET);
		}

		ToM() {
			return this.To(Locr.Length.METER);
		}

		ToMeter() {
			return this.To(Locr.Length.METER);
		}

		ToKM() {
			return this.To(Locr.Length.KILOMETER);
		}

		ToKilometer() {
			return this.To(Locr.Length.KILOMETER);
		}

		ToMI() {
			return this.To(Locr.Length.MILE);
		}

		ToMile() {
			return this.To(Locr.Length.MILE);
		}

		ToPX() {
			return this.To(Locr.Length.PIXEL);
		}

		ToPixel() {
			return this.To(Locr.Length.PIXEL);
		}

		ToPT() {
			return this.To(Locr.Length.POINT);
		}

		ToPoint() {
			return this.To(Locr.Length.POINT);
		}

		ToFormattedString() {
			return this._value + this._unit;
		}
	}

	Object.defineProperty(Locr.Length, 'PIXEL', {
		value: 'px',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'POINT', {
		value: 'pt',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'MILLIMETER', {
		value: 'mm',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'CENTIMETER', {
		value: 'cm',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'DECIMETER', {
		value: 'dm',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'METER', {
		value: 'm',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'KILOMETER', {
		value: 'km',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'FEET', {
		value: 'ft',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'INCH', {
		value: 'in',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'MILE', {
		value: 'mi',
		writable: false,
		enumerable: true,
		configurable: false
	});

	Object.defineProperty(Locr.Length, 'METER_PER_KILOMETER', {
		value: 1000,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'KILOMETER_PER_MILE', {
		value: 1.609344,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'FEET_PER_MILE', {
		value: 5280,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'CENTIMETER_PER_INCH', {
		value: 2.54,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'POINT_PER_INCH', {
		value: 72,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'PIXEL_PER_INCH', {
		value: 96,
		writable: false,
		enumerable: true,
		configurable: false
	});

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function () {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.Math = class LocrMath {
		/**
		 * @param {number} number 
		 */
		static Deg2Rad(number) {
			return number * (Math.PI / 180);
		}

		/**
		 * @param {number} number 
		 */
		static Rad2Deg(number) {
			return number * (180 / Math.PI);
		}

		/**
		 * @param {number} number 
		 * @param {number} precision 
		 */
		static Round(number, precision) {
			if (typeof precision === 'undefined') {
				precision = 0;
			}

			const factor = Math.pow(10, precision);
			const tempNumber = number * factor;
			const roundedTempNumber = Math.round(tempNumber);
			return roundedTempNumber / factor;
		}
	}

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();
'use strict';

(function () {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		let requires = ['length'];
		for (let requireItem of requires) {
			let required = require('./' + requireItem);
			for (let item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.Rectangle = class Rectangle {
		constructor(width, height) {
			this._width = null;
			this._height = null;
			this._dpi = null;
	
			if (typeof width === 'undefined') {
				throw 'The parameter width is undefined. But it must be of type Locr.Length or string.';
			}
			if (typeof height === 'undefined') {
				throw 'The parameter height is undefined. But it must be of type Locr.Length or string.';
			}
	
			if (width instanceof Locr.Length) {
				this._width = width;
			} else if (typeof width === 'string') {
				this._width = new Locr.Length(width);
			} else {
				throw 'The parameter width is not of type Locr.Length or string.';
			}
			if (height instanceof Locr.Length) {
				this._height = height;
			} else if (typeof height === 'string') {
				this._height = new Locr.Length(height);
			} else {
				throw 'The parameter height is not of type Locr.Length or string.';
			}
	
			this._dpi = this._width.DPI;
		}
	
		/**
		 * @returns {number}
		 */
		get DPI() {
			return this._dpi;
		}
	
		/**
		 * @param {number} value
		 */
		set DPI(value) {
			this._width.DPI = value;
			this._height.DPI = value;
			this._dpi = this._width.DPI;
		}
	
		/**
		 * @returns {number}
		 */
		get Width() {
			return this._width.Value;
		}
	
		/**
		 * @returns {number}
		 */
		get Height() {
			return this._height.Value;
		}
	
		/**
		 * @returns {number}
		 */
		get WidthPx() {
			return this._width.ToPixel().Value;
		}
	
		/**
		 * @returns {number}
		 */
		get HeightPx() {
			return this._height.ToPixel().Value;
		}
	}

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function () {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		let requires = ['../../locr', '../geo'];
		for (let requireItem of requires) {
			let required = require('./' + requireItem);
			for (let item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.Geo.Boundings = class Boundings {
		constructor(latitudeMin, latitudeMax, longitudeMin, longitudeMax) {
			this._latitudeMin = -90.0;
			this._latitudeMax = 90.0;
			this._longitudeMin = -180.0;
			this._longitudeMax = 180.0;
			
			if (typeof latitudeMin !== 'undefined' && typeof latitudeMax !== 'undefined' && typeof longitudeMin !== 'undefined' && typeof longitudeMax !== 'undefined') {
				this.LatitudeMin = latitudeMin;
				this.LatitudeMax = latitudeMax;
				this.LongitudeMin = longitudeMin;
				this.LongitudeMax = longitudeMax;
			}
		}
	
		/**
		 * @return number
		 */
		get LatitudeMin() {
			return this._latitudeMin;
		}
	
		/**
		 * @param number|string value
		 */
		set LatitudeMin(value) {
			let valueType = typeof value;
			if (valueType === 'undefined') {
				return;
			}
	
			if (valueType === 'string') {
				value = parseFloat(value);
				if (isNaN(value)) {
					throw 'The value for LatitudeMin is not numeric.';
				}
				valueType = typeof value;
			}
			if (valueType !== 'number') {
				throw 'The value for LatitudeMin is not numeric.';
			}
	
			if (value < -90.0 || value > 90.0) {
				throw 'The value for LatitudeMin must be between -90.0 and 90.0.';
			}
	
			this._latitudeMin = value;
		}
	
		/**
		 * @return number
		 */
		get LatitudeMax() {
			return this._latitudeMax;
		}
	
		/**
		 * @param number|string value
		 */
		set LatitudeMax(value) {
			let valueType = typeof value;
			if (valueType === 'undefined') {
				return;
			}
	
			if (valueType === 'string') {
				value = parseFloat(value);
				if (isNaN(value)) {
					throw 'The value for LatitudeMax is not numeric.';
				}
				valueType = typeof value;
			}
			if (valueType !== 'number') {
				throw 'The value for LatitudeMax is not numeric.';
			}
	
			if (value < -90.0 || value > 90.0) {
				throw 'The value for LatitudeMax must be between -90.0 and 90.0.';
			}
	
			this._latitudeMax = value;
		}
	
		/**
		 * @return number
		 */
		get LongitudeMin() {
			return this._longitudeMin;
		}
	
		/**
		 * @param number|string value
		 */
		set LongitudeMin(value) {
			let valueType = typeof value;
			if (valueType === 'undefined') {
				return;
			}
	
			if (valueType === 'string') {
				value = parseFloat(value);
				if (isNaN(value)) {
					throw 'The value for LongitudeMin is not numeric.';
				}
				valueType = typeof value;
			}
			if (valueType !== 'number') {
				throw 'The value for LongitudeMin is not numeric.';
			}
	
			if (value < -540.0 || value > 540.0) {
				throw 'The value for LongitudeMin must be between -90.0 and 90.0.';
			}
	
			this._longitudeMin = value;
		}
	
		/**
		 * @return number
		 */
		get LongitudeMax() {
			return this._longitudeMax;
		}
	
		/**
		 * @param number|string value
		 */
		set LongitudeMax(value) {
			let valueType = typeof value;
			if (valueType === 'undefined') {
				return;
			}
	
			if (valueType === 'string') {
				value = parseFloat(value);
				if (isNaN(value)) {
					throw 'The value for LongitudeMax is not numeric.';
				}
				valueType = typeof value;
			}
			if (valueType !== 'number') {
				throw 'The value for LongitudeMax is not numeric.';
			}
	
			if (value < -540.0 || value > 540.0) {
				throw 'The value for LongitudeMax must be between -90.0 and 90.0.';
			}
	
			this._longitudeMax = value;
		}
	
		/**
		 * @return Locr.Geo.Position
		 */
		get NorthEast() {
			return new Locr.Geo.Position(this._latitudeMax, this._longitudeMax);
		}
	
		/**
		 * @return Locr.Geo.Position
		 */
		get SouthEast() {
			return new Locr.Geo.Position(this._latitudeMin, this._longitudeMax);
		}
	
		/**
		 * @return Locr.Geo.Position
		 */
		get SouthWest() {
			return new Locr.Geo.Position(this._latitudeMin, this._longitudeMin);
		}
	
		/**
		 * @return Locr.Geo.Position
		 */
		get NorthWest() {
			return new Locr.Geo.Position(this._latitudeMax, this._longitudeMin);
		}

		ExpandByLatitudeLongitude(latitude, longitude) {
			let latitudeType = typeof latitude;
			let longitudeType = typeof longitude;
			if (latitudeType === 'undefined' || longitudeType === 'undefined') {
				return;
			}
	
			if (latitudeType === 'string') {
				latitude = parseFloat(latitude);
				if (isNaN(latitude)) {
					throw 'The latitude not numeric.';
				}
				latitudeType = typeof latitude;
			}
			if (longitudeType === 'string') {
				longitude = parseFloat(longitude);
				if (isNaN(longitude)) {
					throw 'The longitude not numeric.';
				}
				longitudeType = typeof longitude;
			}
			if (latitudeType !== 'number') {
				throw 'The latitude is not numeric.';
			}
			if (longitudeType !== 'number') {
				throw 'The longitude is not numeric.';
			}
	
			if (latitude < -90.0 || latitude > 90.0) {
				throw 'The latitude must be between -90.0 and 90.0.';
			}
			if (longitude < -180.0 || longitude > 180.0) {
				throw 'The longitude must be between -90.0 and 90.0.';
			}
	
			return new Boundings(Math.min(this._latitudeMin, latitude), Math.max(this._latitudeMax, latitude), Math.min(this._longitudeMin, longitude), Math.max(this._longitudeMax, longitude));
		}

		ExpandByPosition(position) {
			if (!(position instanceof Locr.Geo.Position)) {
				throw 'The position must be an instance of Locr.Geo.Position.';
			}
	
			return new Boundings(Math.min(this._latitudeMin, position.Latitude), Math.max(this._latitudeMax, position.Latitude), Math.min(this._longitudeMin, position.Longitude), Math.max(this._longitudeMax, position.Longitude));
		}
	}

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function () {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		let requires = ['../geo'];
		for (let requireItem of requires) {
			let required = require('./' + requireItem);
			for (let item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.Geo.GPolyline = class GPolyline {
		/**
		 * @param {string} encoded
		 * @param {number} precision
		 * @return {array}
		 */
		static Decode(encoded, precision = 5) {
			let coordinates = [];

			let index = 0;
			let lat = 0.0;
			let lng = 0.0;
			let shift = 0;
			let result = 0;
			let byte = 0;
			let latitude_change = 0.0;
			let longitude_change = 0.0;
			let factor = Math.pow(10, precision);

			let strLength = encoded.length;
			while (index < strLength) {
				byte = 0;
				shift = 0;
				result = 0;
	
				do {
					byte = encoded.charCodeAt(index) - 63;
					index++;
					result|= (byte & 0x1f) << shift;
					shift+= 5;
				} while (byte >= 0x20);
	
				latitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));
	
				shift = result = 0;
	
				do {
					byte = encoded.charCodeAt(index) - 63;
					index++;
					result|= (byte & 0x1f) << shift;
					shift+= 5;
				} while (byte >= 0x20);
	
				longitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));
	
				lat+= latitude_change;
				lng+= longitude_change;
	
				let point = [
					lat / factor,
					lng / factor
				];
				coordinates.push(point);
			}

			return coordinates;
		}

		/**
		 * @param {array} coordinates
		 * @param {number} precision
		 * @returns {string}
		 */
		static Encode(coordinates, precision = 5) {
			if (coordinates.length === 0) {
				return '';
			}

			let factor = Math.pow(10, precision);
			let output = '';
			output += GPolyline.EncodePoint(coordinates[0][0], factor);
			output += GPolyline.EncodePoint(coordinates[0][1], factor);

			let previous = coordinates[0];
			for(let i = 1; i < coordinates.length; i++) {
				let current = coordinates[i];
				output+= GPolyline.EncodePoint(current[0] - previous[0], factor);
				output+= GPolyline.EncodePoint(current[1] - previous[1], factor);
				previous = current;
			}

			return output;
		}

		/**
		 * @param {number} value 
		 * @param {number} factor 
		 * @return {string}
		 */
		static EncodePoint(value, factor) {
			let integer = Math.round(value * factor);
			integer <<= 1;
			if (integer < 0) {
				integer = ~integer;
			}

			let output = '';
			while(integer >= 0x20) {
				let chr = ((0x20 | (integer & 0x1f)) + 63);
				output += String.fromCharCode(chr);
				integer >>= 5;
			}
			output += String.fromCharCode(integer + 63);

			return output;
		}
	}

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function () {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		let requires = ['../geo'];
		for (let requireItem of requires) {
			let required = require('./' + requireItem);
			for (let item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.Geo.Position = class Position {
		constructor(latitude, longitude) {
			this._latitude = 0.0;
			this._longitude = 0.0;
	
			if (typeof latitude !== 'undefined' && typeof longitude !== 'undefined') {
				this.Latitude = latitude;
				this.Longitude = longitude;
			}
		}

		/**
		 * @return boolean
		 */
		get IsGeotagged() {
			return (this._latitude !== 0.0 || this._longitude !== 0.0);
		}
	
		/**
		 * @return number
		 */
		get Latitude() {
			return this._latitude;
		}
	
		/**
		 * @param number|string value
		 */
		set Latitude(value) {
			let valueType = typeof value;
			if (valueType === 'undefined') {
				return;
			}
	
			if (valueType === 'string') {
				value = parseFloat(value);
				if (isNaN(value)) {
					throw 'The value for Latitude is not numeric.';
				}
				valueType = typeof value;
			}
			if (valueType !== 'number') {
				throw 'The value for Latitude is not numeric.';
			}
	
			if (value < -90.0 || value > 90.0) {
				throw 'The value for Latitude must be between -90.0 and 90.0.';
			}
	
			this._latitude = value;
		}
	
		/**
		 * @return number
		 */
		get Longitude() {
			return this._longitude;
		}
	
		/**
		 * @param number|string value
		 */
		set Longitude(value) {
			let valueType = typeof value;
			if (valueType === 'undefined') {
				return;
			}
	
			if (valueType === 'string') {
				value = parseFloat(value);
				if (isNaN(value)) {
					throw 'The value for Longitude is not numeric.';
				}
				valueType = typeof value;
			}
			if (valueType !== 'number') {
				throw 'The value for Longitude is not numeric.';
			}
	
			if (value < -540.0 || value > 540.0) {
				throw 'The value for Longitude must be between -540.0 and 540.0.';
			}
	
			this._longitude = value;
		}

		/**
		 * @returns {Locr.Geo.Position}
		 */
		Clone() {
			return new Locr.Geo.Position(this._latitude, this._longitude);
		}
	
		/**
		 * @param {number|string|Locr.Length} length
		 * @returns {Locr.Geo.Boundings}
		 */
		GetBoundingsByLength(length)
		{
			if (length instanceof Locr.Length) {
				length = length.ToMeter().Value;
			} else {
				let lengthType = typeof length;
				if (lengthType === 'string') {
					try {
						length = new Locr.Length(length).ToMeter().Value;
						lengthType = 'number';
					} catch (exc) {
						let parsedLength = parseFloat(length);
						if (isNaN(parsedLength)) {
							throw 'length could not been parsed as number.';
						}
						length = parsedLength;
						lengthType = 'number';
					}
				}
			}
	
			let north = this.GetPositionByDirectionAndLength(0, length);
			let east = this.GetPositionByDirectionAndLength(90, length);
			let south = this.GetPositionByDirectionAndLength(180, length);
			let west = this.GetPositionByDirectionAndLength(270, length);
	
			return new Locr.Geo.Boundings(south.Latitude, north.Latitude, west.Longitude, east.Longitude);
		}
	
		/**
		 * @param {number} direction 
		 * @param {number|string|Locr.Length} length 
		 * @returns {Locr.Geo.Position}
		 */
		GetPositionByDirectionAndLength(direction, length)
		{
			if (length instanceof Locr.Length) {
				length = length.ToMeter().Value;
			} else {
				let lengthType = typeof length;
				if (lengthType === 'string') {
					try {
						length = new Locr.Length(length).ToMeter().Value;
						lengthType = 'number';
					} catch (exc) {
						let parsedLength = parseFloat(length);
						if (isNaN(parsedLength)) {
							throw 'length could not been parsed as number.';
						}
						length = parsedLength;
						lengthType = 'number';
					}
				}
			}
	
			let position = new Position();
	
			let meterPerDegreeLatitude = Locr.Geo.METER_PER_DEGREE_LATITUDE * 60;
			let meterPerDegreeLongitude = Math.abs(Math.cos(this._latitude * Math.PI / 180) * meterPerDegreeLatitude);
	
			if (direction >= 0 && direction <= 90) { // Quadrant 1
				let latMeter = Math.cos(Locr.Math.Deg2Rad(direction)) * length;
				let degreeLatDivider1 = (latMeter != 0) ? meterPerDegreeLatitude / latMeter : 0;
				let longMeter = Math.sin(Locr.Math.Deg2Rad(direction)) * length;
				let degreeLongDivider = (longMeter != 0) ? meterPerDegreeLongitude / longMeter : 0;
	
				let newLatitude1 = (degreeLatDivider1 != 0) ? this._latitude + (1 / degreeLatDivider1) : this._latitude;
				let newLongitude = (degreeLongDivider != 0) ? this._longitude + (1 / degreeLongDivider) : this._longitude;
	
				position.Latitude = newLatitude1;
				position.Longitude = newLongitude;
			} else if (direction >= 90 && direction <= 180) { // Quadrant 2
				let latMeter = Math.sin(Locr.Math.Deg2Rad(direction - 90)) * length;
				let degreeLatDivider = (latMeter != 0) ? meterPerDegreeLatitude / latMeter : 0;
				let longMeter = Math.cos(Locr.Math.Deg2Rad(direction - 90)) * length;
				let degreeLongDivider = (longMeter != 0) ? meterPerDegreeLongitude / longMeter : 0;
	
				let newLatitude = (degreeLatDivider != 0) ? this._latitude - (1 / degreeLatDivider) : this._latitude;
				let newLongitude = (degreeLongDivider != 0) ? this._longitude + (1 / degreeLongDivider) : this._longitude;
	
				position.Latitude = newLatitude;
				position.Longitude = newLongitude;
			} else if (direction >= 180 && direction <= 270) { // Quadrant 3
				let latMeter = Math.cos(Locr.Math.Deg2Rad(direction - 180)) * length;
				let degreeLatDivider = (latMeter != 0) ? meterPerDegreeLatitude / latMeter : 0;
				let longMeter = Math.sin(Locr.Math.Deg2Rad(direction - 180)) * length;
				let degreeLongDivider = (longMeter != 0) ? meterPerDegreeLongitude / longMeter : 0;
	
				let newLatitude = (degreeLatDivider != 0) ? this._latitude - (1 / degreeLatDivider) : this._latitude;
				let newLongitude = (degreeLongDivider != 0) ? this._longitude - (1 / degreeLongDivider) : this._longitude;
	
				position.Latitude = newLatitude;
				position.Longitude = newLongitude;
			} else if (direction >= 270 && direction <= 360) { // Quadrant 4
				let latMeter = Math.sin(Locr.Math.Deg2Rad(direction - 270)) * length;
				let degreeLatDivider = (latMeter != 0) ? meterPerDegreeLatitude / latMeter : 0;
				let longMeter = Math.cos(Locr.Math.Deg2Rad(direction - 270)) * length;
				let degreeLongDivider = (longMeter != 0) ? meterPerDegreeLongitude / longMeter : 0;
	
				let newLatitude = (degreeLatDivider != 0) ? this._latitude + (1 / degreeLatDivider) : this._latitude;
				let newLongitude = (degreeLongDivider != 0) ? this._longitude - (1 / degreeLongDivider) : this._longitude;
	
				position.Latitude = newLatitude;
				position.Longitude = newLongitude;
			}
	
			return position;
		}
	
		ToFormattedLatitude(format) {
			return this.ToFormatted(format, this._latitude, 'latitude');
		}
	
		ToFormattedLongitude(format) {
			return this.ToFormatted(format, this._longitude, 'longitude');
		}
	
		ToFormatted(format, value, latitudeOrLongitude) {
			let formatted = '';
	
			let directionCharacter = '';
			if (latitudeOrLongitude === 'latitude') {
				directionCharacter = (value < 0) ? 'S' : 'N';
			} else {
				directionCharacter = (value < 0) ? 'W' : 'E';
			}
			let dms = this.CalculateDMS(value);
			switch (format) {
				case Locr.Geo.COORDINATE_FORMAT_DECIMAL:
					formatted = value.toString();
					break;
				
				case Locr.Geo.COORDINATE_FORMAT_SIGNED_DEGREES_MINUTES_SECONDS: // (+/-)DD° MM′ SS″
					if (value < 0) {
						formatted = '-';
					}
					formatted += Math.floor(Math.abs(dms.D)) + '° ' + Math.floor(dms.M) + '′ ' + Math.floor(dms.S) + '″';
					break;
				
				case Locr.Geo.COORDINATE_FORMAT_DEGREES_MINUTES_SECONDS_DIRECTION: // DD° MM′ SS″ direction
					formatted = Math.floor(Math.abs(dms.D)) + '° ' + Math.floor(dms.M) + '′ ' + Math.floor(dms.S) + '″ ' + directionCharacter;
					break;
				
				case Locr.Geo.COORDINATE_FORMAT_SIGNED_DEGREES_MINUTES_SECONDS_DECIMAL: // (+/-)DD° MM′ SS.SS″
					if (value < 0) {
						formatted = '-';
					}
					formatted += Math.floor(Math.abs(dms.D)) + '° ' + Math.floor(dms.M) + '′ ' + dms.S.toFixed(2) + '″';
					break;
				
				case Locr.Geo.COORDINATE_FORMAT_DEGREES_MINUTES_SECONDS_DECIMAL_DIRECTION: // DD° MM′ SS.SS″ direction
					formatted = Math.floor(Math.abs(dms.D)) + '° ' + Math.floor(dms.M) + '′ ' + dms.S.toFixed(2) + '″ ' + directionCharacter;
					break;
				
				case Locr.Geo.COORDINATE_FORMAT_SIGNED_DEGREES_MINUTES_DECIMAL: // (+/-)DD° MM.MMMM′
					if (value < 0) {
						formatted = '-';
					}
					formatted += Math.floor(Math.abs(dms.D)) + '° ' + dms.M.toFixed(4) + '′';
					break;
				
				case Locr.Geo.COORDINATE_FORMAT_DEGREES_MINUTES_DECIMAL_DIRECTION: // DD° MM.MMMM′ direction
					formatted = Math.floor(Math.abs(dms.D)) + '° ' + dms.M.toFixed(4) + '′ ' + directionCharacter;
					break;
				
				default:
					throw 'Invalid format given to Locr.Geo.Position.ToFormatted(format, value)';
			}
	
			return formatted;
		}
	
		/**
		 * @param {double} value
		 * @return {object}
		 */
		CalculateDMS(value) {
			const absValue = Math.abs(value);
			const fullDegrees = Math.floor(absValue);
			
			const restMinutes = (absValue - fullDegrees) * 60;
			const fullMinutes = Math.floor(restMinutes);
	
			const restSeconds = (restMinutes - fullMinutes) * 60;
	
			return {
				D: value,
				M: restMinutes,
				S: restSeconds
			};
		}
	}

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();
